var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',{staticClass:"main-cols"},[_c('div',{staticClass:"container is-fluid md:ml-0"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('p',{staticClass:"title has-text-grey-darker text-left"},[_vm._v(" Solicitudes de dinero ")])]),_c('div',{staticClass:"column text-right"},[_c('b-button',{attrs:{"to":{
            name: _vm.activeTab == 2 ? 'solicitar_arrendamiento' : 'solicitar_disposicion',
            params: { pasiva: _vm.activeTab == 1 ? true : false }
          },"tag":"router-link","type":_vm.activeTab == 1 
              ? 'is-pasivo' 
              : _vm.activeTab == 2 ? 'is-arrendamiento' : 'is-primary'}},[_vm._v(" Crear "+_vm._s(_vm.activeTab == 2 ? 'nuevo arrendamiento' : 'nueva disposición')+" ")])],1)]),_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-12"},[_c('tabs',{attrs:{"tabs":_vm.tabs},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}})],1),(_vm.getMe)?_c('bienvenido-card',{attrs:{"pasiva":_vm.activeTab == 1 ? true : false,"arrendamiento":_vm.activeTab == 2 ? true : false,"titulo":'¡Bienvenido a tus ' + _vm.titulo + ' ' + _vm.getMe.alias + '!',"descripcion":"Aquí podrás acceder a todas las solicitudes de disposición para\n        acceder a cada detalle y cambiar su estado, filtrarlas por estado y\n        ver las disposiciones creadas recientemente."}}):_vm._e(),(_vm.disposicionCreada != null)?_c('p',{staticClass:"font-bold mt-4 text-2xl has-text-grey-darker"},[_vm._v(" Disposición reciente ")]):_vm._e(),(_vm.disposicionCreada != null)?_c('DisposicionCard',{staticClass:"w-full mt-2",attrs:{"creada":true,"disposicion":_vm.disposicionCreada},on:{"hideDisposicion":_vm.hideDisposicion}}):_vm._e(),_c('div',{staticClass:"column is-12"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-9"},[(
                _vm.getDisposiciones &&
                  Array.isArray(_vm.getDisposiciones.disposiciones)
              )?_c('Filtros',{attrs:{"disposiciones":_vm.getDisposiciones.disposiciones,"activeTab":Number.parseInt(_vm.activeTab),"tipo_disposicion":_vm.tipo_disposicion,"pagina":_vm.currentPage},on:{"togglecheck":_vm.togglecheck}}):_vm._e()],1),_c('div',{staticClass:"column is-3 flex items-center mt-6 text-right justify-end"},[_c('b-button',{attrs:{"type":_vm.activeTab == 1 
                  ? 'is-pasivo' 
                  : _vm.activeTab == 2 ? 'is-arrendamiento' : 'is-primary',"outlined":""},on:{"click":_vm.toggleOperacionesMasivas}},[_vm._v(_vm._s(_vm.textOperacionMasiva))])],1)])]),(_vm.getProcesarOperaciones)?_c('div',{staticClass:"column is-12 text-left mb-8"},[_c('manejador-status',{attrs:{"status":this.getFiltros.find(function (filtro) { return filtro.filtro == 'Estatus'; }).nombre,"pasivo":_vm.activeTab == 1 ? true : false,"disposicion_id":this.disposiciones_checked,"tipo_disposicion":_vm.tipo_disposicion}})],1):_vm._e(),(_vm.getDisposiciones != null && !_vm.getProcesarOperaciones)?_c('div',{staticClass:"column is-12"},[_c('div',{staticClass:"has-background-white-ter has-border-radius p-10 text-left"},[_c('div',{staticClass:"flex items-center",class:{ 'mb-8': _vm.operaciones_masivas == false }},[(_vm.operaciones_masivas)?_c('div',[_c('b-checkbox',{attrs:{"size":"is-large"},model:{value:(_vm.checkbox_all),callback:function ($$v) {_vm.checkbox_all=$$v},expression:"checkbox_all"}})],1):_vm._e(),_c('p',{staticClass:"text-2xl text-left has-text-grey-darker font-bold inline-block"},[_vm._v(" "+_vm._s(_vm.sizeDisposiciones)+" "+_vm._s(_vm.titulo)+" ")])]),(_vm.operaciones_masivas)?_c('p',{staticClass:"mb-8"},[_vm._v(" "+_vm._s(_vm.disposiciones_checked.length)+" "+_vm._s(_vm.disposiciones_checked.length == 1 ? "disposición seleccionada" : "disposiciones seleccionadas")+" ")]):_vm._e(),(_vm.getDisposicionesFiltradas)?_c('div',_vm._l((_vm.getDisposicionesFiltradas.disposiciones),function(disposicion){return _c('disposicion-card',{key:disposicion.id,attrs:{"disposicion":disposicion,"check":_vm.operaciones_masivas,"check-active":_vm.disposiciones_checked.find(function (disp) { return disp.id == disposicion.id; })
                  ? true
                  : false},on:{"checked":_vm.checkGlobal}})}),1):_c('div',_vm._l((_vm.getDisposiciones.disposiciones),function(disposicion){return _c('disposicion-card',{key:disposicion.id,attrs:{"disposicion":disposicion,"check":_vm.operaciones_masivas}})}),1),(_vm.getDisposiciones != null && _vm.sizeDisposiciones == 0)?_c('empty-state',{attrs:{"imagen":"/images/empty_state_disposiciones.svg","titulo":_vm.getDisposicionesFiltradas != null
                ? 'No tienes ' + _vm.titulo + ' con este filtro'
                : 'No tienes ' + _vm.titulo,"subtitulo":_vm.getDisposicionesFiltradas != null
                ? 'Prueba seleccionando otro filtro'
                : 'Prueba seleccionando otra cartera'}}):_vm._e()],1)]):_vm._e(),_c('div',{staticClass:"column is-12 text-left"},[_c('small',{staticClass:"block"},[_vm._v("mostrando "+_vm._s(_vm.per_page)+" resultados")])]),(!_vm.getProcesarOperaciones)?_c('b-pagination',{staticClass:"mb-8 pl-3",attrs:{"total":_vm.sizeDisposiciones,"current":_vm.currentPage,"range-before":2,"range-after":2,"per-page":_vm.per_page,"icon-prev":"chevron-left","icon-next":"chevron-right","aria-next-label":"Página siguiente","aria-previous-label":"Página anterior","aria-page-label":"Página","aria-current-label":"Página actual"},on:{"update:current":function($event){_vm.currentPage=$event}}}):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }